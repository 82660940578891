export default {
  Auth: {
    Cognito: {
      identityPoolId: 'eu-west-1:952e3dbb-b6cf-4c4d-963d-24066df61833',
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_tCRU4yK3w',
      userPoolClientId: '7p73binc9ioj1ii7h5kjh1s5ha'
    }
  },
  API: {
    REST: {
      'AwsAccountsApi':
      {
        endpoint: "https://84c9ssifa8.execute-api.eu-west-1.amazonaws.com/v1",
        region: "eu-west-1"
      }
    }
  }
}
