import { createRouter, createWebHistory } from 'vue-router'
import AwsAccounts from './views/AwsAccountsView.vue'


const routes = [
  { path: '/', name: 'dashboard', component: AwsAccounts },
  { path: '/aws/accounts', name: 'aws-accounts', component: AwsAccounts }
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: routes
})

export default router
