<script setup>
import { Authenticator } from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';
import { useTheme } from 'vuetify'
import { ref } from 'vue';

const darkTheme = ref(false);

const theme = useTheme();
function toggleDarkTheme() {
  theme.global.name.value = darkTheme.value ? 'dark' : 'light';
}

</script>
<template>
  <Authenticator :hideSignUp="true" variation="modal">
    <template v-slot="{ signOut }">
      <v-app>
        <v-navigation-drawer v-model="drawer">
          <v-list density="compact" nav :lines="false">
            <v-list-item v-for="item in menuItems" :key="item.title" :to="item.link" @click.stop="drawer = !drawer"
              :disabled="item.disabled">
              <template v-slot:prepend>
                <v-icon :color=item.color :icon="item.icon"></v-icon>
              </template>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <template v-slot:prepend>
                <v-list-item-action start>
                  <v-switch v-model=darkTheme @update:modelValue="toggleDarkTheme"></v-switch>
                </v-list-item-action>
              </template>
              <v-list-item-title>Dark Theme</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-app-bar>
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <v-toolbar-title class="text-h5 text-uppercase">
            <span>Account Manager</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div class='sign-out'>
            <v-btn color="primary" variant="text" @click="signOut">Sign out</v-btn>
          </div>
        </v-app-bar>
        <v-main>
          <router-view />
        </v-main>
      </v-app>
    </template>
  </Authenticator>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      drawer: false,
      darkTheme: false,
      menuItems: [
        { icon: 'mdi-cloud', title: 'AWS Accounts', link: { name: 'aws-accounts' } }
      ]
    }
  }
};
</script>
