<script setup>
import "@aws-amplify/ui-vue/styles.css";
</script>
<style>
.is-closed {
  color: red
}
</style>
<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-container fluid>
            <v-row no-gutters>
              <v-col>
                <v-sheet class="pa-2 ma-2">
                  <v-card-title>
                    AWS Accounts {{ count > 0 ? '(' + count + ')' : '' }}
                  </v-card-title>
                </v-sheet>
              </v-col>
              <v-col>
                <v-sheet class="pa-2 ma-2">
                  <v-text-field v-model="search" label="Search" single-line hide-details clearable></v-text-field>
                </v-sheet>
              </v-col>
              <v-col>
                <v-sheet class="pa-2 ma-2">
                  <v-btn v-on:click="getAccounts()" icon variant="text"><v-icon>mdi-refresh</v-icon></v-btn>
                  <v-btn v-on:click.stop="columnsDialog = true" icon
                    variant="text"><v-icon>mdi-cog-outline</v-icon></v-btn>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
          <v-data-table dense :search="search" :loading="loading" :headers="headersSelected" :items="accounts"
            :items-per-page="15" class="elevation-1" :sort-by="[{ key: 'DateOpened.S', order: 'desc' }]">
            <template #[`item.Name.S`]="{ item }">
              {{ item.Name.S }} <v-chip v-if="isClosed(item)" color="error" size="x-small">CLOSED</v-chip>
            </template>
            <template #[`item.Managed.BOOL`]="{ item }">
              <v-icon v-if="isManaged(item) == 'Yes'" color="green">mdi-check-circle</v-icon>
              <v-icon v-if="isManaged(item) == 'No'" color="red">mdi-close-circle</v-icon>
              <v-icon v-if="isManaged(item) == 'Unknown'" color="orange">mdi-help-circle</v-icon>
            </template>
            <template #[`item.Supported.BOOL`]="{ item }">
              <v-icon v-if="isSupported(item) == 'Yes'" color="green">mdi-check-circle</v-icon>
              <v-icon v-if="isSupported(item) == 'No'" color="red">mdi-close-circle</v-icon>
              <v-icon v-if="isSupported(item) == 'Unknown'" color="orange">mdi-help-circle</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-center">
          <v-dialog v-model="columnsDialog" width="500">
            <v-card>
              <v-card-title class="text-h5 bg-grey-lighten-2">
                Table Columns
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-checkbox v-for="header in headersAvailable" v-bind:key="header.title" v-model="headersSelected"
                      :label="header.title" :value="header" :value-comparator="comparator" hide-details
                      v-bind:disabled="header.required ? true : false"></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn color="primary" variant="text" @click="resetHeaders()">
                  Reset
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="text" @click="saveTableSettings()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get } from 'aws-amplify/api'

export default {
  data: () => ({
    headersDefault: [
      {
        title: 'Date Opened',
        align: 'left',
        sortable: true,
        value: 'DateOpened.S',
        required: true
      },
      {
        title: 'Account ID',
        align: 'left',
        sortable: false,
        value: 'Id.S',
        required: true
      },
      {
        title: 'Account Name',
        value: 'Name.S',
        required: true
      },
      {
        title: 'Customer',
        sortable: true,
        value: 'Customer.S'
      },
      {
        title: 'Email Address',
        sortable: true,
        value: 'EmailAddress.S'
      },
      {
        title: 'Parent Account ID',
        sortable: true,
        value: 'ParentId.S'
      }
    ],
    headersSelected: [],
    search: '',
    accounts: [],
    count: 0,
    loading: true,
    columnsDialog: false
  }),
  computed: {
    headersAvailable: function () {
      return [
        ...this.headersDefault,
        {
          title: 'Managed',
          align: 'centre',
          sortable: false,
          value: 'Managed.BOOL'
        },
        {
          title: 'Supported',
          align: 'centre',
          sortable: false,
          value: 'Supported.BOOL'
        },
        {
          title: 'Primary Region',
          align: 'left',
          sortable: false,
          value: 'PrimaryRegion.S'
        },
        {
          title: 'Account Alias',
          align: 'left',
          sortable: false,
          value: 'Alias.S'
        },
        {
          title: 'Date Closed',
          align: 'left',
          sortable: true,
          value: 'DateClosed.S'
        }
      ]
    }
  },
  methods: {
    isManaged: function (item) {
      if (Object.prototype.hasOwnProperty.call(item, 'Managed')) {
        if (item.Managed.BOOL == true) {
          return 'Yes'
        }
        else {
          return 'No'
        }
      }
      else {
        return 'Unknown'
      }
    },
    isSupported: function (item) {
      if (Object.prototype.hasOwnProperty.call(item, 'Supported')) {
        if (item.Supported.BOOL == true) {
          return 'Yes'
        }
        else {
          return 'No'
        }
      }
      else {
        return 'Unknown'
      }
    },
    isClosed: function (item) {
      if (Object.prototype.hasOwnProperty.call(item, 'DateClosed') && item.DateClosed.S) {
        return true
      }
    },
    comparator: function (a, b) {
      return a.title === b.title;
    },
    resetHeaders: function () {
      this.headersSelected = this.headersDefault
    },
    saveTableSettings: function () {
      localStorage.setItem('headers', JSON.stringify(this.headersSelected))
      this.columnsDialog = false
    },
    populateHeaders: function () {
      let headers = JSON.parse(localStorage.getItem('headers'))
      if (!headers) {
        this.headersSelected = this.headersDefault
      }
      else {
        this.headersSelected = headers
      }
    },
    getAccounts: async function () {
      this.loading = true
      let apiName = 'AwsAccountsApi';
      let path = '/accounts';
      try {
        const getOperation = get({ apiName: apiName, path: path });
        const { body } = await getOperation.response;
        const response = await body.json();
        this.accounts = await response.Items;
        this.count = response.Count;

      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error.response)
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)

    }
  },
  created: function () {
    this.getAccounts()
    this.populateHeaders()
  }
};
</script>
