import { createApp } from 'vue'
import { Amplify } from 'aws-amplify'

import App from './App.vue'
import router from './router'
import { vuetify } from './plugins/vuetify';
import config from './aws-exports'

Amplify.configure(config);
const app = createApp(App);
app.use(router).use(vuetify);
app.config.productionTip = false;
app.mount('#app');
